<template>
  <div>
    <h2 class="mb-3">Crystal Violet Hydroxylation: Results - Part C</h2>

    <h3 class="mb-4">Part C</h3>

    <p class="mb-4">
      a) Fill in the table below using the data collected in Part C of the experiment. NOTE: the
      data for the room temperature trial is simply the data from Part A of the experiment.
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table dense>
        <thead>
          <tr>
            <td style="font-weight: bold">Property</td>
            <td style="font-weight: bold; text-align: center">Room Temp</td>
            <td style="font-weight: bold; text-align: center">~30°C Trial</td>
            <td style="font-weight: bold; text-align: center">~35°C Trial</td>
            <td style="font-weight: bold; text-align: center">~40°C Trial</td>
          </tr>

          <tr>
            <td>Concentration of NaOH stock solution (M)</td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.concNaOHStockT1" :disabled="!allowEditing" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.concNaOHStockT2" :disabled="!allowEditing" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.concNaOHStockT3" :disabled="!allowEditing" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.concNaOHStockT4" :disabled="!allowEditing" />
            </td>
          </tr>

          <tr>
            <td>Volume of NaOH used in reaction (mL)</td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.volNaOHUsedT1" :disabled="!allowEditing" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.volNaOHUsedT2" :disabled="!allowEditing" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.volNaOHUsedT3" :disabled="!allowEditing" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.volNaOHUsedT4" :disabled="!allowEditing" />
            </td>
          </tr>

          <tr>
            <td>Concentration of CV+ stock solution (M)</td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input
                v-model="inputs.concCvStockT1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input
                v-model="inputs.concCvStockT2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input
                v-model="inputs.concCvStockT3"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input
                v-model="inputs.concCvStockT4"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Volume of CV+ used in reaction (mL)</td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.volCvUsedT1" :disabled="!allowEditing" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.volCvUsedT2" :disabled="!allowEditing" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.volCvUsedT3" :disabled="!allowEditing" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.volCvUsedT4" :disabled="!allowEditing" />
            </td>
          </tr>

          <tr>
            <td>[NaOH] at start of reaction (M)</td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.concNaOHUsedT1" :disabled="!allowEditing" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.concNaOHUsedT2" :disabled="!allowEditing" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.concNaOHUsedT3" :disabled="!allowEditing" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.concNaOHUsedT4" :disabled="!allowEditing" />
            </td>
          </tr>

          <tr>
            <td>Temperature (°C)</td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.tempT1" :disabled="!allowEditing" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.tempT2" :disabled="!allowEditing" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.tempT3" :disabled="!allowEditing" />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input v-model="inputs.tempT4" :disabled="!allowEditing" />
            </td>
          </tr>

          <tr>
            <td>Effective rate constant, <stemble-latex content="$\text{k}_\text{eff}$" /></td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input
                v-model="inputs.kEffT1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input
                v-model="inputs.kEffT2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input
                v-model="inputs.kEffT3"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input
                v-model="inputs.kEffT4"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Rate constant, <stemble-latex content="$\text{k}$" /></td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input
                v-model="inputs.kT1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input
                v-model="inputs.kT2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input
                v-model="inputs.kT3"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input py-2 px-2 mx-0">
              <calculation-input
                v-model="inputs.kT4"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </p>

    <p class="mb-2">
      b) Generate an Arrhenius plot using the program of your choice. What is the slope of the plot
      using the appropriate units for the Arrhenius equation?
    </p>

    <calculation-input
      v-model="inputs.slope"
      class="mb-5"
      prepend-text="$\text{slope:}$"
      append-text="$\text{Units not provided}$"
      :disabled="!allowEditing"
    />

    <p class="mb-2">
      c) What is the activation energy, <stemble-latex content="$\text{E}_\text{a},$" /> for the
      hydroxylation of crystal violet based on the information gathered in this experiment?
    </p>

    <calculation-input
      v-model="inputs.Ea"
      prepend-text="$\text{E}_\text{a}\text{:}$"
      append-text="$\text{kJ/mol}$"
      :disabled="!allowEditing"
    />
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'CrystalViolet_ResultsC',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        concNaOHStockT1: null,
        volNaOHUsedT1: null,
        concCvStockT1: null,
        volCvUsedT1: null,
        concNaOHUsedT1: null,
        tempT1: null,
        kEffT1: null,
        kT1: null,

        concNaOHStockT2: null,
        volNaOHUsedT2: null,
        concCvStockT2: null,
        volCvUsedT2: null,
        concNaOHUsedT2: null,
        tempT2: null,
        kEffT2: null,
        kT2: null,

        concNaOHStockT3: null,
        volNaOHUsedT3: null,
        concCvStockT3: null,
        volCvUsedT3: null,
        concNaOHUsedT3: null,
        tempT3: null,
        kEffT3: null,
        kT3: null,

        concNaOHStockT4: null,
        volNaOHUsedT4: null,
        concCvStockT4: null,
        volCvUsedT4: null,
        concNaOHUsedT4: null,
        tempT4: null,
        kEffT4: null,
        kT4: null,

        slope: null,
        Ea: null,
      },
    };
  },
};
</script>

<style>
input[type='number'] {
  -moz-appearance: textfield; /*For FireFox*/

  &::-webkit-inner-spin-button {
    /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
